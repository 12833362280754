/* HERO IMAGE */
.hero-img-portfolio {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* WORK DETAILS */
.work-details {
	padding: 100px 0 50px;
	background-color: #fff;
	img {
		width: 100%;
		margin: auto;
		margin-bottom: 30px;
	}
	.desc {
		font-size: 13px;
		color: #000;
		line-height: 22px;
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 64px;
		}
	}
	.more-info {
		border-bottom: 1px solid #eee;
		padding-bottom: 60px;
		margin-bottom: 40px;
		.info-title {
			font-size: 16px;
			color: #000;
			text-transform: uppercase;
			letter-spacing: 3px;
			&:after {
				content: '';
				background-color: #000;
				width: 40px;
				height: 1px;
				display: block;
				margin-bottom: 14px;
				margin-top: 14px;
			}
		}
		.info-text {
			font-size: 16px;
			color: #707070;
			letter-spacing: 0.7px;
		}
		.social-media {
			a i {
				color: #707070;
				margin-right: 12px;
				&:hover {
					color: #9b9b9b;
				}
			}
		}
		@media(max-width: 768px) {
			.col-1-4 {
				width: 50%;
				.info-text {
					margin-bottom: 40px;
				}
			}
		}
		@media(max-width: 480px) {
			.col-1-4 {
				width: 100%;
			}
		}
	}
	.navigation {
		text-align: center;
		a i {
			font-size: 30px;
			margin-right: 20px;
			display: inline-block;
			color: #767676;
			&:hover {
				color: #000;
			}
		}
		a:last-child {
			i:last-child {
				margin-right: 0;
			}
		}
	}
}

