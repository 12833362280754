/* OVERALL */
a {
	text-decoration: none;
}

img {
	vertical-align: middle;
}

body {
	font-family: $mainfont;
}

/* LOGO & NAVBAR */
.nav-container {
	width: 100%;
	padding: 0 40px;
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	border-bottom: 1px solid #eeeeee;
	/* LOGO */
	h1.logo {
		float: left;
		margin-top: 15px;
		a {
			color: #000;
			font-size: 24px;
			color: #333333;
			font-family: $secondfont;
			font-weight: 500;
			letter-spacing: 3px;
		}
		@media (max-width: 700px) {
			position: absolute;
			top: 0;
		}
	}
	/* NAVBAR */
	.main-nav {
		ul.main-menu {
			margin: 0;
			padding: 0;
			float: right;
			margin-top: 6px;
			> li {
				float: left;
				margin-left: 30px;
				padding-top: 16px;
				padding-bottom: 22px;
				&:first-child {
					margin-left: 0;
				}
				a {
					color: #7e7e7e;
					font-size: 13px;
					text-transform: uppercase;
					letter-spacing: 0.4px;
					&:hover {
						color: #c8c8c8;
					}
					&.active {
						color: #343434;
					}
				}
			}
			.dropdown {
				position: relative;
				.drop-nav {
					position: absolute;
					z-index: 999;
					left: -10px;
					top: 100%;
					background-color: #fff;
					display: none;
					> li a {
						width: 100%;
						display: block;
						padding: 15px;
						margin-right: 50px;
						border-bottom: 1px solid #ebebeb;
					}
				}
				&:hover > .drop-nav {
					display: block;
					-webkit-box-shadow: 1px 1px 10px 2px rgba(0,0,0,.05);
  					-moz-box-shadow: 1px 1px 10px 2px rgba(0,0,0,.05);
  					box-shadow: 1px 1px 10px 2px rgba(0,0,0,.05);
					> li a:hover {
						background-color: #f5f5f5;
						color: #7e7e7e;
					}
				}
			}
		}
		@media (max-width: 700px) {
			display: none;
		}
	}
	@media (max-width: 700px) {
		position: static;
	}
}

/* BUTTON */
.btn {
	width: 175px;
	height: 50px;
	background-color: #000;
	display: block;
	font-family: $mainfont;
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	letter-spacing: 0.7px;
	text-align: center;
	padding-top: 18px;
	&:hover {
		background-color: rgba(0,0,0,.5);
	}
	@media(max-width: 768px) {
		margin: auto;
	}
}

/* FOOTER */
footer {
	padding: 30px 0 40px;
	background-color: #fff;
	border-top: 1px solid #eeeeee;
	text-align: center;
	p {
		font-size: 13px;
		color: #7f7f7f;
		margin-bottom: 20px;
	}
	i {
		color: #9b9b9b;
		margin-right: 10px;
		&:hover {
			color: #bdbdbd;
		}
	}
	a:last-child {
		i:last-child {
			margin-right: 0;
		}
	}
}