// Fonts
$mainfont 	: "Questrial", sans-serif ;
$secondfont	: "Raleway", sans-serif ;

// FONT COLORS
$titlecolor	: #343434;


// BACKROUND COLORS
$gray	: #fafafa;
$divider: #eeeeee;


// FONT SIZES
$titlesize	: 24px;
$textsize	: 13px;


// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

 