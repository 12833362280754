/* HERO IMAGE */
.hero-img-portfolio {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}
