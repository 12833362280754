/* HERO IMAGE */
.hero-img-contact {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* CONTACT */
.contact-us {
	padding: 90px 0;
	background-color: #fff;
	.contact-title {
		font-size: 28px;
		color: #000;
		text-transform: uppercase;
		letter-spacing: 4.5px;
		margin-bottom: 50px;
	}
	.contact-info {
		position: relative;
		font-size: 15px;
		color: #343434;
		letter-spacing: 0.7px;
		padding-left: 46px;
		border-bottom: 1px solid #eee;
		max-width: 490px;
		padding-bottom: 16px;
		margin-bottom: 16px;
		i {
			position: absolute;
			top: -2px;
			left: 0;
			color: #646464;
			font-size: 22px;
		}
	}
	.contact-forms {
		float: right;
		max-width: 500px;
		width: 100%;
		input[type=text] {
			width: 100%;
			// max-width: 100%;
			height: 42px;
			margin-bottom: 30px;
			outline: none;
			font-size: 10px;
			color: #4f4f4f;
			text-transform: uppercase;
			padding-left: 10px;
			border: 1px solid #dcdcdc;
			&:hover {
				background-color: #f5f5f5;
				border: 1px solid #6b6b6b;
			}
		}
		input:focus {
			background-color: #f5f5f5;
			outline: none;
			border: 1px solid #6b6b6b;
		}
		textarea {
			width: 100%;
			height: 200px;
			margin-bottom: 30px;
			resize: none;
			outline: none;
			font-size: 10px;
			color: #4f4f4f;
			text-transform: uppercase;
			padding-left: 10px;
			padding-top: 10px;
			border: 1px solid #dcdcdc;
			&:hover {
				background-color: #f5f5f5;
				border: 1px solid #6b6b6b;
			}
		}
		textarea:focus {
			background-color: #f5f5f5;
			outline: none;
			border: 1px solid #6b6b6b;
		}
		@media(max-width: 768px) {
			max-width: 100%;
			width: 100%;
			float: none;
		}
		.btn-send {
			float: right;
		}
	}
}

/* GOOGLE MAP */
#map {
	height: 500px;
}
#map img {  
    max-width: none;   
} 
