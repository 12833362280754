// Css base
@import 'core/vars';
@import 'core/reset';
@import 'core/grid';



// Site
@import 'site/site';
@import 'site/home'; 
@import 'site/about'; 
@import 'site/blog-sidebar'; 
@import 'site/blog-no-sidebar'; 
@import 'site/blog-single'; 
@import 'site/portfolio-1'; 
@import 'site/portfolio-2'; 
@import 'site/work-single'; 
@import 'site/contact'; 


 


// Vendors -- PLUGINS
@import 'vendors/slicknav';
@import 'vendors/flexslider';
 
