/* HERO IMAGE */
.hero-img-about {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* SERVICES SECTION */
.services {
	padding: 100px 0 50px;
	background-color: #fff;
	.service {
		text-align: center;
		i {
			font-size: 50px;
			color: #000;
			margin-bottom: 22px;
			display: block;
		}
		.serv-title {
			font-size: 16px;
			text-transform: uppercase;
			color: #343434;
			letter-spacing: 3px;
			margin-bottom: 28px;
			&:after {
				content: '';
				width: 40px;
				height: 1px;
				background-color: #000;
				display: block;
				margin: auto;
				margin-top: 12px;
			}
		}
		.serv-description {
			font-size: 13px;
			color: #444444;
			line-height: 20px;
		}
		@media(max-width: 768px) {
			margin-bottom: 70px;
		}
	}
}

/* SECTION TITLE & DESCRIPTION STYLE */
h3.sec-title {
	font-size: 24px;
	color: #343434;
	text-transform: uppercase;
	letter-spacing: 3px;
	margin-bottom: 5px;
	text-align: center;
}
p.sec-description {
	font-size: 13px;
	color: #343434;
	letter-spacing: 0.7px;
	text-align: center;
	&:after {
		content: '';
		width: 40px;
		height: 1px;
		background-color: #343434;
		display: block;
		margin: auto;
		margin-top: 24px;
		margin-bottom: 40px;
	}
}

/* WHO WE ARE */
.who-we-are {
	padding: 60px 0 90px;
	background-color: #fff;
	text-align: center;
	.hover {
		display: block;
		max-width: 277px;
		// margin: auto;
		&:hover .image-wrap .image-hover {
			background-color: rgba(44,44,44,.9);
			transition: .9s;
			.text-wrap {
				opacity: 1;
				transition: .9s;
			}
		}
		.image-wrap {
			position: relative;
			img {
				max-width: 100%;
			}
			.image-hover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(44,44,44,0);
				text-align: center;
				.text-wrap {
					width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					opacity: 0;
					.name {
						font-size: 20px;
						color: #fff;
						text-transform: uppercase;
						letter-spacing: 2.5px;
						margin-bottom: 12px;
					}
					.post {
						font-size: 13px;
						color: #fff;
						letter-spacing: 1px;
						&:after {
							content: '';
							width: 40px;
							height: 1px;
							background-color: #fff;
							display: block;
							margin: auto;
							margin-top: 18px;
							margin-bottom: 18px;
						}
					}
					.social-media {
						a {
							color: #a3a3a3;
							margin-right: 8px;
							&:last-of-type {
								margin-right: 0;
							}
							&:hover {
								color: #fff;
							}
						}
					}
				}
			}
			
		}
	}
	.hover.left {
		float: left;
	}
	.hover.center {
		margin: auto;
	}
	.hover.right {
		float: right;
	}

	@media(max-width: 768px) {
		.hover.left, .hover.center, .hover.right {
			float: none;
			margin: auto;
			margin-bottom: 40px;
		}
	}
}

/* TESTIMONIALS / WHAT CLIENTS SAY SECTION */
.testimonials {
	padding: 70px 0;
	background-color: #fafafa;
	text-align: center;
	.sec-description {
		margin-bottom: 52px;
	}
	.flexslider {
		max-width: 600px;
		margin: auto;
		text-align: center;
		@media(max-width: 630px) {
			padding: 0 90px;
		}
		@media(max-width: 530px) {
			padding: 0 40px;
		}
		@media(max-width: 320px) {
			padding: 0;
		}
		p.testimonial-txt {
			font-size: 13px;
			color: #646464;
			line-height: 24px;
		}
		p.client {
			font-size: 14px;
			color: #343434;
			margin-bottom: 30px;
			margin-top: 24px;
			span {
				color: #7f7f7f;
			}
		}
	}
}
