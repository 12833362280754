/* HERO IMAGE */
.hero-img-portfolio {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* WORK */
.work.portfolio-2 {
	padding: 60px 0 90px;
	background-color: #fff;
	.work-group {
		margin-bottom: 30px;
	}
	.work-item	{
		a {
			display: block;
			&:hover .img-wrap .img-hover {
				background-color: rgba(0,0,0,.7);
				transition: .9s;
				.caption-text {
					opacity: 1;
					transition: .9s;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
				}
			}
			.img-wrap {
				position: relative;
				img {
					width: 100%;
					display: block;
					vertical-align: middle;
				}
				.img-hover {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,0);
					text-align: center;
					.caption-text {
						position: absolute;
						top: 0%;
						left: 50%;
						transform: translate(-50%,0);
						opacity: 0;
						width: 100%;
						.work-title {
							font-size: 30px;
							text-transform: uppercase;
							color: #fff;
							margin-bottom: 10px;
						}
						.genre {
							font-size: 14px;
							font-family: $secondfont;
							text-transform: lowercase;
							color: #fff;
						}
					}
				}
			}
		}
	}
	@media(max-width: 768px) {
		.work-group {
			margin-bottom: 0px;
		}
		.col-1-4 {
			width: 50%;
			.work-item {
				margin-bottom: 30px;
			}
		}
	}
	@media(max-width: 480px) {
		.col-1-4 {
			width: 100%;
			.work-item {
				margin-bottom: 30px;
			}
		}
	}
}