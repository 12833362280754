/* HERO IMAGE */
.hero-img-blog {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* BLOG */
.blog {
	padding: 100px 0 0;
	background-color: #fff;
	.post {
		border-bottom: 1px solid #eee;
		padding-bottom: 30px;
		margin-bottom: 50px;
		&:last-child {
			border: none;
		}
		a.post-title {
			font-size: 22px;
			color: #646464;
			letter-spacing: 5px;
			text-transform: uppercase;
			margin-bottom: 10px;
			display: inline-block;
		}
		.date {
			font-size: 13px;
			color: #646464;
			letter-spacing: 0.5px;
			margin-bottom: 40px;
		}
		img.post-img {
			margin-bottom: 24px;
		}
		.article-txt {
			font-size: 13px;
			color: #1d1d1d;
			line-height: 22px;
			margin-bottom: 20px;
			max-width: 780px;
		}
		.btn-blog {
			margin-top: 34px;
		}
	}
	/* SIDEBAR */
	.sidebar {
		.sidebar-title {
			font-size: 18px;
			color: #646464;
			letter-spacing: 3px;
			text-transform: uppercase;
			margin-bottom: 68px;
		}
		.latest-posts {
			margin-bottom: 70px;
			ul.side-posts {
				li {
					overflow: hidden;
					img.img-sm {
						float: left;
						margin-right: 14px;
						width: 40%;
						max-width: 100px;
						margin-bottom: 24px;
					}
					.sm-post-title {
						float: left;
						font-size: 15px;
						text-transform: uppercase;
						color: #000;
						margin-bottom: 8px;
						letter-spacing: 3px;
						width: 50%;
					}
					.sm-post-date {
						float: left;
						font-size: 13px;
						text-transform: uppercase;
						color: #000;
						letter-spacing: 1px;
						width: 50%;
					}
				}
			}
		}
		.categories {
			margin-bottom: 70px;
			.cetegorie-name {
				font-size: 15px;
				text-transform: uppercase;
				color: #7d7d7d;
				margin-bottom: 8px;
				letter-spacing: 3px;
				width: 50%;
				border-bottom: 1px solid #eee;
				width: 100%;
				display: block;
				padding-bottom: 16px;
				margin-bottom: 22px;
				&:hover {
					color: #000;
				}
			}
		}
	}
}
