/* GRID */
.container-sm {
	max-width: 1026px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
	.col-1-2 {
		margin-bottom: 30px;
	}
	.col-1.right {
		margin-bottom: 30px;
	}
	.img-wrap.side {
		margin-bottom: 30px;
	}
	@media(max-width: 768px) {
		.col-1-2 {
			width: 50%;
			margin-bottom: 40px;
		}
		text-align: center;
		img {
			max-width: 100%;
		}
		.img-wrap.side {
			max-height: 286px;
			overflow: hidden;
		}
		.col-1-3 {
			.col-1 {
				width: 50%;
			}
		}
		.col-2-3 {
			.col-1 {
				margin-bottom: 40px;
			}
		}
	}
	@media(max-width: 720px) {
		[class*='col-'] {
			width: 100%;
		}
		.col-1-3 {
			.col-1 {
				width: 100%;
			}
		}
		.col-2-3 {
			.col-1 {
				img {
					width: 308px;
				}
			}
		}
		.col-1.right {
			margin-bottom: 40px;
		}
	}
}

/* HERO IMAGE */
.hero-img {
	background: url("../images/hero.jpg") no-repeat;
	height: 600px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0,-50%);
		padding: 0 110px;
		width: 100%;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 8px;
			letter-spacing: 0.7px;
		}
		.description {
			font-size: 14px;
			color: #000;
			line-height: 20px;
			&:last-of-type {
				margin-bottom: 70px;
			}
		}
		@media(max-width: 768px) {
			text-align: center;
			padding: 50px;
			.description {
				&:last-of-type {
					margin-bottom: 40px;
				}
			}
		}
	}
	@media(max-width: 768px) {
		height: 350px;
	}
}

/* WORK */
.work {
	padding: 100px 0;
	background-color: #fff;
	h2.title {
		font-size: 30px;
		text-transform: uppercase;
		color: #7f7f7f;
		text-align: center;
		letter-spacing: 5px;
		margin-bottom: 100px;
	}
	a {
		display: block;
		&:hover .img-wrap .img-hover {
			background-color: rgba(0,0,0,.7);
			transition: .9s;
			.caption-text {
				opacity: 1;
				transition: .9s;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
		.img-wrap {
			position: relative;
			
			img {
				width: 100%;
				display: block;
				vertical-align: middle;
			}
			.img-hover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0);
				text-align: center;
				.caption-text {
					position: absolute;
					top: 0%;
					left: 50%;
					transform: translate(-50%,0);
					opacity: 0;
					.work-title {
						font-size: 30px;
						text-transform: uppercase;
						color: #fff;
						margin-bottom: 10px;
					}
					.genre {
						font-size: 14px;
						font-family: $secondfont;
						text-transform: lowercase;
						color: #fff;
					}
				}
			}
			@media(max-width: 720px) {
				max-width: 308px;
				margin: auto;
				display: block;
				text-align: center;
				.col-1.right .img-hover.side {
					max-height: 50px;
				}
			}
		}
		@media(max-width: 720px) {
			max-width: 308px;
			margin: auto;
			display: block;
			text-align: center;
		}
	}
}