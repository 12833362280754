/* HERO IMAGE */
.hero-img-blog {
	background: url("../images/hero.jpg") no-repeat;
	height: 440px;
	background-size: cover;
	position: relative;
	.text-wrap {
		position: absolute;
		top: 55%;
		left: 0;
		transform: translate(0,-50%);
		width: 100%;
		text-align: center;
		.head-title {
			font-size: 40px;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 12px;
			letter-spacing: 5px;
		}
		.description {
			font-size: 13px;
			color: #343434;
			line-height: 20px;
			letter-spacing: 0.7px;
		}
	}
}

/* BLOG */
.container-blog {
	max-width: 1000px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
}

.blog-no-sidebar {
	padding: 50px 0 0;
	background-color: #fff;
	.post {
		border-bottom: 1px solid #eee;
		padding-bottom: 30px;
		margin-bottom: 50px;
		&:last-child {
			border: none;
		}
		a.post-title {
			font-size: 22px;
			color: #646464;
			letter-spacing: 5px;
			text-transform: uppercase;
			margin-bottom: 10px;
			display: block;
			text-align: center;
		}
		.date {
			font-size: 13px;
			color: #646464;
			letter-spacing: 0.5px;
			margin-bottom: 40px;
			text-align: center;
		}
		img.post-img {
			margin-bottom: 24px;
			width: 100%;
		}
		.article-txt {
			font-size: 13px;
			color: #1d1d1d;
			line-height: 22px;
			margin-bottom: 20px;
			max-width: 100%;
		}
		.btn-blog {
			margin-top: 34px;
		}
	}
}
